<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลรายงานอัตรากำลังของวิทยาลัย แยกตามประเภทวิชา"
        class="px-5 py-3"
      >
        <v-row no-gutters>
          <v-col cols="12" md="12">
            <v-card>
              <div id="chart">
                <apexchart
                  type="bar"
                  height="500"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        />
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="personnel_etcs"
          :search="search"
          disable-pagination
        >
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>
    </v-container>
  </div>
</template>
<script>
import VueMoment from "vue-moment";
import moment from "moment-timezone";
export default {
  name: "personnel_etc",
  data() {
    return {
      VueMoment,
      moment,
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,

      personnel_etcs: [],
      search: "",
      pagination: {},
      headers: [
        {
          text: "รหัสประเภทวิชา",
          align: "center",
          value: "id_type_course"
        },
        { text: "ประเภทวิชา", align: "left", value: "subject_typeName" },
        {
          text: "จำนวนข้าราชการครู",
          align: "center",
          value: "countPersonSubjectTeach"
        },
        {
          text: "จำนวนพนักงานราชการครู",
          align: "center",
          value: "countPersonSubjectTeachGov"
        },
        {
          text: "จำนวนครูจ้างสอน",
          align: "center",
          value: "countPersonSubjectTeachTem"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ], 
      
      series: [
        {
          name: 'จำนวน',
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: 'bar',
        },               
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          categories: [],
        },
        tooltip: {},
      },


    };
  },
  created() {
    this.personnel_etcQueryAll();
  },

  methods: {
    async personnel_etcQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("personnel_etc.php", {
          ApiKey: this.ApiKey,
          subjectCount: "Ok"
        }).finally(() => (this.loading = false));
        let datafilter = result.data
        this.personnel_etcs = datafilter.filter(value => value.countPersonSubjectTeach != '0' && value.countPersonSubjectTeachGov != '0' && value.countPersonSubjectTeachTem != '0')


        let labelText = []
      let valuesst1 = []
      let valuesst2 = []
      let valuesst3 = []
      let data = this.personnel_etcs
      data.forEach((value) => {
        labelText.push(value.subject_typeName)
        valuesst1.push(value.countPersonSubjectTeach)
        valuesst2.push(value.countPersonSubjectTeachGov)
        valuesst3.push(value.countPersonSubjectTeachTem)
      })

      let valueshowaf1 = valuesst1.map((i) => Number(i))
      let valueshowaf2 = valuesst2.map((i) => Number(i))
      let valueshowaf3 = valuesst3.map((i) => Number(i))
      this.series = [
        {
          name: 'ข้าราชการครู',
          data: valueshowaf1,
        },
        {
          name: 'พนักงานราชการ',
          data: valueshowaf2,
        },
        {
          name: 'ครูอัตราจ้าง',
          data: valueshowaf3,
        }          
      ]
      this.chartOptions= {
            chart: {
              height: 350,
              type: 'bar',
            },
          plotOptions: {
              bar: {
                horizontal: true,
                dataLabels: {
                  position: 'top',
                },
              }
            },
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return val;
              },
              offsetY: -20,
              style: {
                fontSize: '12px',
                colors: ["#304758"]
              }
            },
            
            xaxis: {
              categories: labelText,
              position: 'bottom',
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false
              },
              crosshairs: {
                fill: {
                  type: 'gradient',
                  gradient: {
                    colorFrom: '#D8E3F0',
                    colorTo: '#BED1E6',
                    stops: [0, 100],
                    opacityFrom: 0.4,
                    opacityTo: 0.5,
                  }
                }
              },
              tooltip: {
                enabled: true,
              }
            },
            yaxis: {
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: false,
                formatter: function (val) {
                  return val + " คน";
                }
              }
            
            },
                 }


       
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>


<!-- 
 .then(result => {
          result.data.forEach(element => {
            this.numX.push(element.subject_typeName);
            this.numY.push(element.countPersonSubjectTeach);
            this.numZ.push(element.countPersonSubjectTeachGov);
            this.numAss.push(element.countPersonSubjectTeachTem);
          });
          this.series = [
            {
              data: this.numY
            },
            {
              data: this.numZ
            },
            {
              data: this.numAss
            }
          ];
          this.chartOptions = { xaxis: { categories: this.numX } };
          this.personnel_etcs = result.data;
        })
        .finally(() => (this.loading = false));


-->
